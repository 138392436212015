import * as d3 from "d3";
import randomString from "randomstring";
import { Events } from "../../../plugins/events";
import { ENDPOINTS } from "../../../config";
import { Seat } from "./Seat";

export default {
    data(){
        return {
            loading: false,
            seatingPreviewDialog: false,
            editor: {
                mode: 'select', // default mode
                zoom: 1,
            },
            planName: null,
            copiedElements: [],
            modes: {
                'pan': {
                    name: 'pan',
                    label: 'Zoom',
                    icon: 'mdi-cursor-pointer',
                    handler: () => {
                        this.stage.container().style.cursor = 'grab';
                        this.deselectNodes();
                    }
                },
                'select': {
                    name: 'select',
                    label: this.$t("eventbee.lang_row"),
                    icon: 'mdi-cursor-default',
                    handler: () => {
                        this.stage.container().style.cursor = 'default';
                        this.deselectNodes();
                    }
                },
                'select-seat':{
                    name: 'select-seat',
                    label: this.$t("eventbee.lang_seat"),
                    icon: 'mdi-cursor-default-outline',
                    handler: () => {
                        this.stage.container().style.cursor = 'default';
                        this.deselectNodes();
                    }
                },
                'draw-rows': {
                    name: 'draw-rows',
                    icon: 'mdi-dots-grid',
                    label: this.$t("eventbee.lang_rows"),
                    handler: () => {
                        this.stage.container().style.cursor = 'crosshair';
                        this.deselectNodes();
                    }
                },
                'draw-row': {
                    name: 'draw-row',
                    icon: 'mdi-dots-horizontal',
                    label: this.$t("eventbee.lang_row"),
                    handler: () => {
                        this.stage.container().style.cursor = 'crosshair';
                        this.deselectNodes();
                    }
                },
                'add-table': {
                    name: 'add-table',
                    icon: 'TableRectIcon',
                    svgIcon: true,
                    handler: () => {
                        this.stage.container().style.cursor = 'default';
                        this.deselectNodes();
                        
                        // Convert viewport center to stage coordinates
                        const centerPoint = this.getCenterOfScreenRelativeToStage();


                        let rectTableData = this.generateTableData(
                            "Table Rect",
                            centerPoint.x,
                            centerPoint.y,
                            "rect",
                            0,
                            1,
                            1,
                            Math.random() > 0.5
                        );
                        this.renderTable(rectTableData);
                    }
                },
                'add-table-round': {
                    name: 'add-table-round',
                    icon: 'TableRoundIcon',
                    svgIcon: true,
                    handler: () => {
                        this.deselectNodes();
                        this.stage.container().style.cursor = 'default';
                        const centerPoint = this.getCenterOfScreenRelativeToStage();
                        const roundTableData = this.generateTableData(
                            "Table Round",
                            centerPoint.x,
                            centerPoint.y,
                            "circle",
                            4,
                            0,
                            0,
                            Math.random() > 0.5
                        );
                        this.renderTable(roundTableData);
                    }
                },
                'add-area-rect': {
                    name: 'add-area-rect',
                    icon: 'mdi-rectangle-outline',
                    handler: () => {
                        this.stage.container().style.cursor = 'crosshair';
                        this.deselectNodes();
                    }
                },
                'add-area-circle': {
                    name: 'add-area-circle',
                    icon: 'mdi-circle-outline',
                    handler: () => {
                        this.stage.container().style.cursor = 'crosshair';
                        this.deselectNodes();
                    }
                },
                // 'bg-overlay': {
                //     name: 'bg-overlay',
                //     icon: 'mdi-image-edit',
                //     handler: () => {
                        
                //     }
                // },   
            },
            seatCategories: [],
            seatingData: {},
        }
    },
    watch: {
        "editor.mode": {
            handler(oldVal, newVal){
                if(newVal === "select-seat"){
                    this.toggleRowNumbers(true);
                }else if(oldVal === "select-seat"){
                    this.toggleRowNumbers(false);
                }

                if(oldVal === "pan" || newVal === "pan"){
                    this.disableListening(this.editor.mode !== "pan");// disable event listening to prevent dragging and hovering effects
                }
            }
        }
    },
    computed:{
        seatCategoriesMap(){
            return new Map(this.seatCategories.map(category => [category.id, category]));
        },
        filteredModes(){
            if(this.showPricing){
                return {
                    'pan':this.modes['pan'],
                    'select':this.modes['select'],
                    'select-seat':this.modes['select-seat'],
                }
            }

            return this.modes;
        },
        assignedCategoryIds() {
            return [...new Set(this.seatsAttrs
                .map(seat => seat.category)
                .filter(category => category !== undefined && category !== '')
            )];
        }
    },
    methods: {
        disableListening(enable){
            this.layer.listening(enable);
            this.transformerLayer.listening(enable);
            this.bgLayer.listening(enable);
            this.overlayLayer.listening(enable);
        },
        getCategoryColor(categoryId) {
            const category = this.seatCategoriesMap.get(categoryId);
            return category ? category.color : '#ffffff'; // Default color if category not found
        },
        getSeatCategories(reload=false){
            this.loading = true;
            this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.SEATCATEGORY.GET, {
            }).then((res) => {
                if (res.data.status) {
                    this.seatCategories = res.data.data;
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: err.message,
                    color: "error"
                });
            }).finally(()=>{
                if(reload)
                    this.loading = false;
            })
        },
        toggleBgOverlayMode(enabled) {
            this.editor.mode = 'select';
            this.toggleBackground = enabled;
            this.smoothZoom(2 - this.backgroundImage.scaleX());
            if (enabled) {
                // Disable other layers
                this.layer.opacity(0.3);
                this.transformerLayer.hide();
                this.overlayLayer.hide();

                // Bring background layer to top
                this.bgLayer.moveToTop();

                // Make background image editable
                if (this.backgroundImage) {
                    this.backgroundImage.listening(true)
                    this.backgroundImage.draggable(true)
                    // Add transformer to background image
                    this.bgTransformer.nodes([this.backgroundImage]);
                }
            } else {
                // Restore layers
                this.layer.opacity(1);
                this.transformerLayer.show();
                this.overlayLayer.show();

                // Move background layer back
                this.bgLayer.moveToBottom();

                // Make background image non-editable
                if (this.backgroundImage) {
                    this.backgroundImage.listening(false)
                    this.backgroundImage.draggable(false)
                    this.bgTransformer.nodes([]);
                }
            }
        },
        updateBackgroundTransform() {
            // Update background image properties based on transformation
            const scaleX = this.backgroundImage.scaleX();
            const scaleY = this.backgroundImage.scaleY();

            this.backgroundImageScale = { x: scaleX, y: scaleY };
            this.backgroundImagePosition = { x: this.backgroundImage.x(), y: this.backgroundImage.y() };

            // You might want to save these values or update some UI elements
        },
        getCenterOfScreenRelativeToStage() {
            const container = d3.select(this.stage.container());
            const currentTransform = d3.zoomTransform(container.node());

            // Get the dimensions of the viewport
            const viewportWidth = this.width;
            const viewportHeight = this.height;

            // Calculate the center of the viewport in the current transformed coordinate system
            const viewportCenterX = (-currentTransform.x + viewportWidth / 2) / currentTransform.k;
            const viewportCenterY = (-currentTransform.y + viewportHeight / 2) / currentTransform.k;
            return {
                x: viewportCenterX/2,
                y: viewportCenterY/2,
            };
        },

        copySelected(){
            if(this.selectedTypes.includes("seat")){
                this.copiedElements = [];
            }else {
                this.copiedElements = this.selected.map(node => node.clone());
            }
        },

        pasteAtMousePosition() {
            if (this.copiedElements.length === 0) return;

            const mousePos = this.stage.getRelativePointerPosition();
            const dx = mousePos.x;
            const dy = mousePos.y;

            this.deselectNodes();

            this.copiedElements.forEach(element => {
                const clone = element.clone();
                clone.removeName('selected');
                // Generate new IDs for the cloned elements
                
                clone.absolutePosition({
                    x: clone.x() + dx,
                    y: clone.y() + dy
                });
                
                if (clone.name() === 'table') {
                    const tableNameText = this.overlayLayer.findOne(`#table-name-${clone.id()}`);
                    if(tableNameText){
                        clone.tableNameText = tableNameText.clone();
                        this.overlayLayer.add(clone.tableNameText)
                        this.updateTableNamePosition(clone);
                    }
                }
                
                if (clone.name() === 'row') {
                    clone.rowBackBone = clone.findOne(".rowBackBone");
                    clone.rowNumberStart = clone.findOne(".row-number-start");
                    clone.rowNumberEnd = clone.findOne(".row-number-end");
                    
                    this.positionRowNumbers(clone);
                    this.updateRowBackBone(clone, false);
                }
                
                this.updateIds(clone);
                this.layer.add(clone);
            });
            // @TODO: select clones by default
            this.layer.batchDraw();
            //this.handleCache();
        },

        updateIds(node) {
            if (node.name() === 'row') {
                node.id(randomString.generate(10));
                node.find('.seat').forEach(seat => {
                    seat.attrs.seat_id = randomString.generate(10);
                    seat.attrs.row_id = node.id();
                });
            } else if (node.name() === 'table') {
                const newTableId = randomString.generate(10);
                node.id(newTableId);
                node.tableNameText.id(`table-name-${newTableId}`)
                node.find('.table-seat').forEach(seat => {
                    seat.attrs.seat_id = randomString.generate(10);
                    seat.attrs.table_id = newTableId;
                });
            } else if (node.name() === 'seat') {
                node.attrs.seat_id = randomString.generate(10);
            }
        },
        getRowRawData(row){
            const { 
                id,
                name,
                row_number,
                row_number_position,
                row_id,
                x,
                y,
                rotation,
            } = row.attrs;

            return {
                id,
                name,
                row_number,
                row_number_position,
                row_id,
                x,
                y,
                rotation,
                seats: []
            };
        },
        getSeatRawData(seat, isRow = true){
            const { 
                id,
                name,
                seat_number,
                seat_id,
                seatIndex,
                x,
                y,
                row_id,
                table_id,
                rotation,
                category,
                quality
            } = seat.attrs;

            return { 
                id,
                name,
                seat_number,
                seat_id,
                row_id: (isRow? row_id : undefined),
                table_id: (!isRow? table_id: undefined),
                seatIndex,
                x,
                y,
                rotation,
                quality,
                category
            }
        },
        getTableRawData(table){
            const {x, y, id,rotation, table_name, shape, table_id, tableNameVisible, xSeats, ySeats, numberOfSeats, minBookableSeats, reserveTableAfterBooking} = table.attrs

            return {
                id, x, y, table_name, shape, table_id, rotation, tableNameVisible, xSeats, ySeats, numberOfSeats, minBookableSeats, reserveTableAfterBooking,
                seats: []
            }
        },
        getAreaRawData(shape){
            const icon = shape.contentGroup.findOne('.area-icon');
            const text = shape.contentGroup.findOne('.area-text');

            return {
                id: shape.attrs.area_id,
                x: shape.x(),
                y: shape.y(),
                isRectangle: shape.getClassName() === 'Rect',
                rotation: shape.rotation(),
                scaleX: shape.scaleX(),
                scaleY: shape.scaleY(),
                width: shape.width(),
                height: shape.height(),
                radius: shape.radius ? shape.radius() : undefined,
                cornerRadius: shape.cornerRadius ? shape.cornerRadius() : undefined,
                fill: shape.fill(),
                stroke: shape.stroke(),
                strokeWidth: shape.strokeWidth(),

                iconName: icon.attrs.iconName,
                iconColor: icon.fill(),
                iconVisible: icon.visible(),
                iconSize: icon.scaleX(),

                text: text.text(),
                textColor: text.fill(),
                textSize: text.fontSize(),
                textVisible: text.visible(),
            }
        },
        createSeatingData(){
            this.loading = true;
            this.axios.post(ENDPOINTS.EVENTBEE.SEATING_MAP.CREAT, {
                name: this.planName,
                data: this.seatingData
            }).then(res => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_savedSuccessfully'),
                    color: "success"
                });
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorWhileSaving'),
                    color: "error"
                });
            }).finally(()=>{
                this.loading = false;
            })
        },
        updateSeatingData(){
            this.loading = true;
            this.axios.post(ENDPOINTS.EVENTBEE.SEATING_MAP.UPDATE, {
                uuid: this.$route.params.uuid,
                name: this.planName,
                data: this.seatingData,
                assignedSeats: this.getAssignedSeats(),
                backgroundImage: this.backgroundImageUrl,
                imageUpdate: this.isBackgroundImageUpdated,
                deleteImage: this.deleteBackgroundImage,
                used_seat_categories: this.assignedCategoryIds
            }).then(res => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_savedSuccessfully'),
                    color: "success"
                });
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorWhileSaving'),
                    color: "error"
                });
            }).finally(()=>{
                this.loading = false;
            })
        },
        async getSeatingData(){
            this.loading = true;
            await this.axios.post(ENDPOINTS.EVENTBEE.SEATING_MAP.GET,{
                uuid: this.$route.params.uuid
            }).then(async res => {
                if(res.data.status){
                    this.planName = res.data.data.name;
                    await this.renderSeatingData(res.data.data.data);
                    this.seatingData =  res.data.data.data;
                    this.setBackgroundImage(res.data.data.data.backgroundImage, this.imageUrl(res.data.data.backgroundImage));
                }else {
                    throw new Error()
                }
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorWhileSaving'),
                    color: "error"
                });
            }).finally(()=>{
                this.loading = false;
            })
        },
        async renderSeatingData(seatingData) {
            this.loading = true;
            // Clear existing elements
            this.layer.destroyChildren();
            Seat.showQuality = false;
            // Render rows
            if (seatingData.rows && seatingData.rows.length) {
                await this.renderRowsAndSeats(seatingData.rows);
            }
            
            // Render tables
            if (seatingData.tables && seatingData.tables.length) {
                for(const tableData of seatingData.tables) {
                    this.renderTable(tableData);
                    await new Promise(requestAnimationFrame);
                }
            }
            
            // Render areas
            if (seatingData.areas && seatingData.areas.length) {
                for(const areaData of seatingData.areas){
                    this.renderArea(areaData);
                    await new Promise(requestAnimationFrame);
                } 
            }
            
            // Render focal point
            if(seatingData.focalPoint){
                this.addFocalPoint(seatingData.focalPoint);
            }else {
                this.addFocalPoint();
            }

            // Refresh the layer
            this.layer.batchDraw();
            await new Promise(requestAnimationFrame);
            this.loading = false;
        },
        saveSeatingData() {
            this.deselectNodes();
            const seatingData = {
                focalPoint: null,
                rows: [],
                tables: [],
                areas: [],
                backgroundImage: null
            };
            // Save row data
            const rowGroups = this.layer.find('.row');
            rowGroups.forEach(rowGroup => {
                const rowData = this.getRowRawData(rowGroup);

                const seats = rowGroup.find('.seat');
                seats.forEach(seat => {
                    rowData.seats.push(this.getSeatRawData(seat));
                });
                seatingData.rows.push(rowData);
            });

            // Save table data
            const tableGroups = this.layer.find('.table');
            tableGroups.forEach(tableGroup => {
                const tableData = this.getTableRawData(tableGroup)

                const tableSeats = tableGroup.find('.table-seat');
                tableSeats.forEach(seat => {
                    tableData.seats.push(this.getSeatRawData(seat, false));
                });
                seatingData.tables.push(tableData);
            });

            const areaGroups = this.layer.find('.area-shape');
            areaGroups.forEach(shape => {
                seatingData.areas.push(this.getAreaRawData(shape));
            });
            
            // Save focal point position;
            seatingData.focalPoint = this.focalPoint.handle.getAbsolutePosition(this.stage);

            // Save backgorund image attrs
            const  {x,y, scaleX, scaleY, width, height, rotation,opacity} = this.backgroundImage?.attrs ;
            seatingData.backgroundImage = {x,y, scaleX, scaleY, width, height, rotation, opacity};
            this.seatingData = seatingData;
            this.updateSeatingData();
        },
    },
    async mounted() {
        await this.getSeatCategories();
        this.getSeatingData();
    },
    beforeDestroy() {
        if (this.stage) {
            this.stage.off('dragstart');
            this.stage.off('dragend');
            this.stage.off('click tap');
            this.stage.destroy();
            this.stage = null;
        }
    },
}