import { Shape } from "konva";
import { COLORS, SEAT_RADIUS, SHOW_TEXT_THRESHOLD } from "./constants";
import {hexToHexa} from "./helpers";

export class Seat extends Shape {
    static showQuality = false;
    constructor(config) {
        super({
            ...config,
            radius: config.radius || SEAT_RADIUS,
            width: (config.radius || SEAT_RADIUS) * 2,
            height: (config.radius || SEAT_RADIUS) * 2,
            category: config.category || '',
            fontSize: config.fontSize || 10,
            showSeatNumber: Boolean(config.showSeatNumber),
            fontFamily: config.fontFamily || 'Calibri',
            fill: config.fill || "#fff",
            stroke: config.stroke || COLORS[7][1],
        });
        this._centroid = true;
    }

    _sceneFunc(context, shape) {
        const attrs = shape.attrs;
        const isSelected = shape.hasName("selected") || shape.getParent()?.hasName("selected");
        
        this._setStyle(attrs, isSelected);

        // Draw the circle
        context.beginPath();
        context.arc(0, 0, attrs.radius, 0, Math.PI * 2, false);
        context.closePath();
        context.fillStrokeShape(shape);
        
        // Draw the seat number
        if (this._shouldDrawSeatNumber(attrs)) {
            context.font = `${attrs.fontSize}px ${attrs.fontFamily}`;
            context.fillStyle = attrs.fontColor;
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(attrs.seat_number, 0, 0);
        }
    }

    _setStyle(attrs, isSelected) {
        if (attrs.category) {
            attrs.fill = attrs.categoryColor;
            attrs.fontColor = this._getContrastColor(attrs.categoryColor);
        } else {
            attrs.fill = "#ffffff";
            attrs.fontColor = "black";
        }

        if(Seat.showQuality){
            attrs.fill = hexToHexa(attrs.categoryColor, attrs.quality>=0.2?attrs.quality:0.1)
            attrs.fontColor = "black";
        }

        attrs.stroke = isSelected ? COLORS[2][0] : COLORS[7][1];
        attrs.strokeWidth = isSelected ? 2 : 1;
    }

    _shouldDrawSeatNumber(attrs) {
        return this.getStage().scaleX() > SHOW_TEXT_THRESHOLD || attrs.showSeatNumber;
    }

    _getContrastColor(hexColor) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128 ? 'black' : 'white';
    }

    getWidth() {
        return this.attrs.radius * 2;
    }

    getHeight() {
        return this.attrs.radius * 2;
    }

    setSeatNumber(number) {
        this.attrs.seat_number = number;
        this.getLayer().batchDraw();
    }
}

Seat.prototype.className = "Seat";