<template>
    <v-expansion-panels accordion flat class="mt-1">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div>
                    <v-icon class="mr-1" color="warning">mdi-alert-outline</v-icon>
                    {{ $t('generic.lang_warnings') }}
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-alert outlined color="warning" class="mb-0">
                    <li v-for="(msg, index) in warnings" :key="index">{{ msg }}</li>
                </v-alert>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

export default {
    name:"WarningsToolBox",
    props: {
        warnings:{
            type: Array,
            default: ()=> []
        }
    }
}

</script>